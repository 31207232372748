'use strict';

function getLocation() {
    var sessionStore = $('.session-store').data('storeid');
    var locationMapping = $('.location-mapping').val();
    locationMapping = locationMapping && JSON.parse(locationMapping);
    var location = locationMapping[sessionStore];
    return location;
}

module.exports = {
    init: function () {
        if ($('.form-url').val() !== '') {
            $('.contact-us-block').find('.container').load($('.form-url').val(), function () {
                setTimeout(function () {
                    if ($('.contact-department').val() && $('select[name="Account.Contact_Inquiry__c.A.Department__c"]').length > 0) {
                        $('select[name="Account.Contact_Inquiry__c.A.Department__c"]').val($('.contact-department').val()).trigger('change');
                    }
                    var location = getLocation();
                    if (location) {
                        $('select[name*="Location__c"]').val(location).trigger('change');
                    }
                    if ($('input[name*="Current_Page_URL__c"]').length > 0) {
                        $('input[name*="Current_Page_URL__c"]').val(window.location.href);
                    }
                    if ($('head title').length === 0) {
                        $('head').append('<title></title>');
                    }
                    $('head title').text($('.custom-page-title').val());
                }, 2500);
            });
        }
    }
};
